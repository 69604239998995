// change highlight colors
.el-date-table td.current:not(.disabled) span {
  background-color: $white-ter;
}
.el-date-picker__header-label.active,
.el-date-picker__header-label:hover,
.el-date-table td.today span,
.el-picker-panel__icon-btn:hover,
.el-month-table td .cell:hover,
.el-date-table td.available:hover,
.el-month-table td.current:not(.disabled) .cell {
  color: $primary;
}

.el-picker-panel td.available {
  color: $grey-dark;
}

// change text colors
.el-picker-panel {
  color: $grey;
}

// change cell background color
.el-date-table td.disabled div {
  background-color: $white-bis;
}

// make small datepicker the same as bulma regular
.el-date-editor.el-input--small {

  .el-input__inner {
    height: 36px;
    border-radius: $border-radius-small;
  }

  .el-input__prefix, .el-input__suffix {
    top: 1px;
  }
}

