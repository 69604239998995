// reduce the fluid container gap
$gap: 15px;

// add more padding to boxes
$box-padding: 1.5rem;

// default border radius
$border-radius-small: 3px;
$border-radius-default: 5px;
$border-radius-large: 8px;

// change link color and background color on nav
$navbar-item-color: $grey;
$navbar-item-hover-background-color: $white;
$navbar-item-hover-color: $black-ter;

// alter the navbar
$navbar-height: 4rem;
$navbar-item-active-color: $primary;
$navbar-item-active-background-color: $white-bis;

// alter the panel heading
$panel-heading-line-height:	1.5;
$panel-heading-padding:	1em;
$panel-heading-size: 0.75rem;
