// override the element ui popover styles
.el-popover {
  border: 1px solid $border;
  padding: 25px 27px;
  color: $text;
  line-height: 1.5;
  text-align: left;
  font-size: 1rem;
  word-wrap: normal;
  word-break: normal;
  box-shadow: 0 6px 40px 0 shade(0.15);
}