@import "/home/jenkins/agent/workspace/deployments/grin.live/deploy-grin.live/resources/assets/node_modules/@grininc/grin-ui/style/variables/index.scss";










































































































































































.image-slide {
    width: 100%;
    height: 425px;
}
.bg-image {
    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}
.product-title-height {
    height: 45px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}
.product-description {
    word-break: break-word;
    @media only screen and (max-width: 800px) {
        line-height: 18px;
    }
}
.product-title-details {
    font-size: 16px !important;
    font-weight: bold !important;
    margin-bottom: 10px !important;
    line-height: 22px !important;
}
