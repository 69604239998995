.dropzone-control {
  background-color: $white-bis;
  display: flex;
  height: 200px;

  .dropzone,
  .blank-state {
    align-items: center;
    border: 1px dashed $grey-lighter;
    box-sizing: border-box;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: center;
  }

  .dropzone {
    cursor: pointer;

    &:hover {
      border-color: $primary;

      i {
        color: $primary;
      }
    }

    i {
      font-size: 2rem;
      opacity: 0.2;
    }

    .dz-message {
      margin-top: 20px;

      span span {
        color: $primary;
      }
    }
  }

  .blank-state {
    background: none;
    padding: 0;
  }
}