.campaign-form-title {
  text-transform: uppercase;
  font-size: 12px;
}

.section.campaign-details {
  padding: 0;
}

.image-overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, rgba(0,0,0,0.05), rgba(0,0,0,0.85));
}

.campaign-details-content {
  position: absolute;
  bottom: 0;
  padding: 5rem;
  .campaign-details-by  {
    color: $white;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 0.9rem;
    opacity: 0.85;
  }
  h3 {
    color: $white;
    font-size: 1.75rem;
    font-weight: 700;
    line-height: 2rem;
    margin-bottom: 1rem;
  }
  p {
    color: $white;
    font-size: 1rem;
    opacity: 0.85;
    font-weight: 400;
  }
}

.campaign-form-title {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.9rem;
}

.tasks-icon {
  width: 40px;
  .icon {
    font-size: 26px;
  }
}
.tasks-type {
  width: calc(100% - 50px)
}

.title:not(:last-child), .subtitle:not(:last-child) {
  margin-bottom: 0.5rem;
}

.campaign-image img {
  border-radius: 5px;
}

.box.campaign-task {
  padding: 1rem 0.5rem !important;
  box-shadow: none !important;
}
.box.campaign-task:not(:last-child) {
  margin-bottom: 0;
  border-bottom: 1px dashed $border !important;
}

.cash-price {
  font-size: 30px;
  color: $green;
  font-weight: bold;
  letter-spacing: -2px;
}

.task-title-row {
  float: left;
  width: 100%;
  .task-title-col {
    float: left;

    &.task-check {
      width: 40px;
      float: right;
    }
    &.task-icon {
      width: 32px;
      margin-top: 3px;
      .icon {
        font-size: 22px !important;
      }
    }
    &.task-type {
    }
  }
}

.proposal-action {
  padding-top: 10px;
  position: relative;
  background-color: #fff;
  width: 100%;

  .field {
    margin-bottom: 0;
  }
}

.deal-form-title {
  text-transform: none;
  font-weight: 600;
  font-size: 18px;

  @media only screen and (max-width: 800px) {
    font-size: 15px;
  }
}