// change the background color of the bar
.el-slider__bar {
  background-color: $primary;
}

// change the border color of the slider knob
.el-slider__button {
  border-color: $primary;
}

