@import "/home/jenkins/agent/workspace/deployments/grin.live/deploy-grin.live/resources/assets/node_modules/@grininc/grin-ui/style/variables/index.scss";













.data-accept {
    font-size: 0.9em;
    #terms {
        vertical-align: middle;
    }
    a {
        color: #3a424b;
        text-decoration: underline;
    }
}
