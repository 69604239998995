.live-nav {
  background-color: $dark;
  padding: 15px;
  float: left;
  width: 100%;
  a {
    color: #fff;
    float: left;
    display: block;
    padding: 5px;
    margin-right: 10px;
    opacity: 0.6;

    &.active {
      opacity: 1;
    }
  }
}

.nav-container {
  //margin-bottom: 25px;
}