.box {
  border-radius: 0;
}

.box:not(:last-child) {
  margin-bottom: 0;
  border-bottom: 0 !important;
}

@media only screen and (max-width: 800px) {
  .box {
    box-shadow: none;
    border: 1px solid lighten($border, 5%) !important;
  }
  .box.box-dashed {
    border: 1px dashed lighten($border, 5%) !important;
  }
}

