.el-tabs__header {
  background-color: $white;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.el-tabs__nav-wrap {
  padding-left: 30px;
  &::after {
    height: 1px;
    background-color: $border;
  }
}

.el-tabs__item {
  padding: 15px 25px;
  height: auto;
  font-weight: 500;
  color: $grey-light;
  text-transform: uppercase;
  font-size: 0.75rem;
  outline: none !important;
  &:hover {
    color: $grey-dark;
  }
  &.is-active {
    font-weight: 600;
    color: $grey-dark;
  }
}

.el-tabs__active-bar {
  background-color: $primary;
  height: 3px;
}

// tabs inside a box
.box-tabs.el-tabs {

  .el-tabs__nav-wrap::after {
    height: 0;
    background-color: transparent;
  }

  .el-tabs__header {
    box-shadow: 0 1px 2px rgba(9, 10, 12, 0.1);
  }

  .el-tabs__item {
    font-size: 1rem;
    padding: 10px 20px;
    text-transform: none;

    &.is-active {
      font-weight: 700;
      color: $grey-darker;
    }
  }


}

