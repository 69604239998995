// icon colors
.icon-color {
  // social network
  &.fa-youtube { color: $youtube; }
  &.fa-facebook-f { color: $facebook; }
  &.fa-instagram { color: $instagram; }
  &.fa-twitter { color: $twitter; }
  &.fa-twitch { color: $twitch; }
  &.fa-linkedin { color: $linkedin; }
  &.fa-pinterest-p, &.fa-pinterest { color: $pinterest; }
  &.fa-tumblr { color: $tumblr; }

  // gender
  &.fa-neuter { color: $neutral; }
  &.fa-mars, &.fa-male { color: $male; }
  &.fa-venus, &.fa-female { color: $female; }

  // location
  &.fa-globe { color: $grey-dark; }
  &.fa-map-marker { color: $red; }
}


// solid dot icon
.dot {
  border-radius: 50%;
  height: 15px;
  width: 15px;
  display: inline-block;
}

// font colors
.has-text-green .dot { background-color: $green !important; }
.has-text-red .dot { background-color: $red !important; }
.has-text-orange .dot { background-color: $orange !important; }
.has-text-blue .dot { background-color: $blue !important; }
.has-text-primary .dot { background-color: $primary !important; }
.has-text-turquoise .dot { background-color: $turquoise !important; }

// change the cursor for sortable icons
.icon.sort-handle {
  cursor: pointer;
}
