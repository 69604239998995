// make selects full width
.el-select {
  width: 100%;
}

// change the small height to match inputs
.el-input--small .el-input__inner {
  height: 36px !important;
}

// change the hover color to match inputs
.el-select .el-input__inner:hover {
  border-color: $grey-light;
}
// change the focus color to match inputs
.el-select .el-input__inner:focus {
  border-color: $primary;
}
// change the selected color
.el-select-dropdown__item.selected {
  color: $primary;
}

// hide the empty search text
.el-select-dropdown__empty {
  display: none;
}