// we do not use Element UI's icons, we are using FontAwesome 5
// so we have to override the actual icon classes to get it working

@mixin fa() {
  font-family: 'Font Awesome 5 Regular' !important;
}

// all icon classes
[class*=" el-icon-"], [class^=el-icon-], .el-input__icon {
  @include fa;
  font-size: 16px;
}

// add the correct with to input icons
.el-input__icon {
  width: 2.25em;
  color: $grey-lighter;
}

.el-input__prefix {
  transition: none;
}

// change color on input focus
.el-input__inner:focus ~ .el-input__prefix .el-input__icon,
.el-input__inner:focus ~ .el-input__suffix .el-input__icon {
  color: $grey;
}
.el-input__inner ~ .el-input__suffix .el-input__icon {
  color: $grey;
  cursor: pointer;
  &:hover {
    color: $red;
  }
}

// circle close
.el-icon-circle-close:before { content: "\f057"; }

.el-icon-close::before { content: "\f00d"; }

// arrow right
.el-icon-arrow-left:before { content: "\f104"; }
// arrow right
.el-icon-arrow-right:before { content: "\f105"; }

// double arrow right
.el-icon-d-arrow-right:before { content: "\f101"; }
// double arrow left
.el-icon-d-arrow-left:before { content: "\f100"; }

// arrow up
.el-icon-arrow-up:before { content: "\f106"; }
// change the arrow color when inside a .select
.el-select .el-icon-arrow-up:before { color: $primary; }

// more (elipsis)
.el-icon-more:before { content: "\f141" }

// date
.el-icon-date:before { content: "\f073" }

// plus
.el-icon-plus::before { content: "\f067" }
// minus
.el-icon-minus::before { content: "\f068" }