// add border radius to cards
.card {
  border-radius: 0;
}

a.card-header-title {
  color: $primary;
}

// add a padded card header
.card .card-header.is-padded {
  padding: 0 2rem;
  height: 5rem;

  .card-header-title {
    padding: 0;
  }
}

// add more padding to the card content
.card-content {
  padding: 2rem;
}

.card-header-icon {
  transition: background 0.2s ease, color 0.2s ease;
  &:hover {
    background-color: $white-ter;
  }
  &:last-child {
    border-top-right-radius: $border-radius-default;
  }
  &.is-static {
    cursor: default;
    &:hover {
      background-color: transparent;
    }
  }
}

// change footer item link color
a.card-footer-item,
.card-footer-item a {
  color: $grey;
  &:hover {
    color: $primary;
  }
}

a.card-footer-item {
  // add colors
  &.is-success { &:hover { color: $green; } }
  &.is-danger { &:hover { color: $red; } }
  &.is-warning { &:hover { color: $orange; } }
  &.is-info { &:hover { color: $info; } }
  &.is-primary { &:hover { color: $primary; } }
}

.card-footer-item {
  // add colors
  &.is-success a { &:hover { color: $green; } }
  &.is-danger a { &:hover { color: $red; } }
  &.is-warning a { &:hover { color: $orange; } }
  &.is-info a { &:hover { color: $info; } }
  &.is-primary a { &:hover { color: $primary; } }
}

