// we are forced to use some Element form components so we
// need to override some styles to make it look like bulma

// always use size="large" for Element inputs
.el-input.el-input--large {
  width: 100% !important;
}

// make the inner look like other inputs
.el-input__inner {
  height: 3.5rem;
  font-size: 1rem;
  background-color: $white-bis;
  line-height: 1.5;
  color: $grey-darker;
  @include shadow-input-inner;
  transition: none;

  &::placeholder {
    color: $placeholder;
  }

  &:hover {
    border-color: $grey-light;
    @include shadow-input-inner;
  }
}

// add correct padding to prefixed inputs
.el-input__prefix {
  left: 2px;
}
.el-input--prefix .el-input__inner {
  padding-left: 2.25em;
}