@import "/home/jenkins/agent/workspace/deployments/grin.live/deploy-grin.live/resources/assets/node_modules/@grininc/grin-ui/style/variables/index.scss";







































































































































































































































































































.connect-failed-wrapper {
    text-align: center;

    .margin-top-2 {
        margin-top: $g-spacing-7;
    }

    .margin-bottom-3 {
        margin-bottom: $g-spacing-9;
    }

    .h1 {
        font-size: 1.25rem;
        max-width: 350px;
        text-align: center;
        margin: 0 auto $g-spacing-6 auto;
    }

    .logo-style {
        width: 184px;
        margin-top: $g-spacing-7;
        margin-bottom: $g-spacing-7;
    }

    .text-wrapper {
        margin: 0 auto;
        width: 290px;
    }

    .margin-p {
        margin-bottom: $g-spacing-5;
    }

    .info-list {
        text-align: left;
        margin: 30px 15%;
        background-color: rgba(237, 240, 242, 0.5);
        border-radius: 10px;
        padding: 25px 25px 25px 50px;

        li {
            line-height: 1.75;
            color: $g-font-color-body;
        }
    }

    .info-list-wrapper {
        padding-left: 25px;
    }

    .connect-button {
        display: inline-block;
        background-color: #303c4a;
        font-size: 1rem;
        font-weight: 500;
        color: #fff;
        width: 174px;
        height: 48px;
        border-radius: 24px;
        box-shadow: 0 4px 8px rgba(48, 60, 74, 0.2);

        &:hover {
            border: 0;
        }
    }
}
