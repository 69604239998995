@import "/home/jenkins/agent/workspace/deployments/grin.live/deploy-grin.live/resources/assets/node_modules/@grininc/grin-ui/style/variables/index.scss";






































































































.margin-bottom-normal {
    margin-bottom: $g-spacing-5;
}

.margin-bottom-large {
    margin-bottom: $g-spacing-6;
}

.collapsible-card p:last-child {
    margin-bottom: 0;
}
