@import "/home/jenkins/agent/workspace/deployments/grin.live/deploy-grin.live/resources/assets/node_modules/@grininc/grin-ui/style/variables/index.scss";











































































































































































































































































































































































::v-deep .ant-form-item-label label {
    font-size: $g-font-size-h5;
    font-weight: $g-font-weight-bold;
    text-transform: uppercase;
}
