// lighten the default notification bg
.notification {
  background-color: rgba(238, 240, 242, 0.5);
  padding: 0.5rem 1rem;
}

// add inverted notification classes
.notification.is-inverted {
  &.is-primary {
    color: $primary;
    background-color: lighten($primary, 48%);
  }
  &.is-success {
    color: $green;
    background-color: lighten($green, 48%);
  }
  &.is-warning {
    color: $orange;
    background-color: lighten($orange, 44%);
  }
  &.is-danger {
    color: $red;
    background-color: lighten($red, 37%);
  }
}