// remove underline for a tag add-ons
a.tag:hover {
  text-decoration: none;
}

// add inverted helpers
.tag.is-inverted {

  &.is-success {
    color: $green;
    border-color: lighten($green, 40%);
    background-color: lighten($green, 48%);
  }
  &.is-primary {
    color: $primary;
    border-color: lighten($primary, 40%);
    background-color: lighten($primary, 48%);
  }
  &.is-danger {
    color: $red;
    border-color: lighten($red, 29%);
    background-color: lighten($red, 37%);
  }
  &.is-warning {
    color: $orange;
    border-color: lighten($orange, 36%);
    background-color: lighten($orange, 44%);
  }

  a.icon.is-tiny {
    color: $grey-darker !important;
  }
}