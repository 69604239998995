@import "/home/jenkins/agent/workspace/deployments/grin.live/deploy-grin.live/resources/assets/node_modules/@grininc/grin-ui/style/variables/index.scss";
















































































.FullscreenDrawer {
    padding-top: 0;
    max-width: 100% !important;
    left: 0 !important;
    .FullscreenDrawer__content {
        max-width: 650px;
        margin: 0 auto;
        .FullscreenDrawer__content--has-title {
            margin-top: 55px;
        }
    }
    .FullscreenDrawer__content.FullscreenDrawer__content--has-title {
        margin-top: 55px;
    }
    .FullscreenDrawer__header-button-container {
        position: absolute;
        top: 18px;
        left: 24px;
        z-index: 2;
        cursor: pointer;
    }
}

::v-deep .ant-drawer-header {
    display: flex;
    justify-content: center;
    position: absolute;
    width: 100%;
    z-index: 1;
    height: 55px;
}
::v-deep .ant-drawer-title {
    font-weight: 600 !important;
    font-size: 1.125rem !important;
}
