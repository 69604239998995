// cloak the app while its initializing
[v-cloak] {
  display: none;
}

// add a fixed position helper
.is-fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  &.is-fullwidth {
    width: 100%;
  }
}

.is-fullwidth {
  width: 100%;
}

.is-hidden {
  display: none !important;
}

.overflow-wrapper {
  width: 100%;
  overflow: scroll;
}