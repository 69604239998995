.product-wizard {

  .breadcrumb {
    border-bottom: 1px solid $border;
    padding: 0 25px;
    margin-bottom: 0;

    @media (max-width: 720px) {
      padding: 0 15px 0 10px;
      font-size: 14px;
    }
  }

  .gender-box {
    .gender-icon {
      .icon {
        font-size: 30px;
      }
    }
  }

  .media + .media {
    margin-top: 15px;
  }
}

.success-block {
  padding: 15px;
  border: 1px dashed $border !important;
  color: $green;
  border-radius: 4px;
  strong {
    color: lighten($dark, 10%);
  }
}

.selected-product {
  border: 1px dashed $border !important;
  padding: 10px;
  align-items: center;
  border-radius: 4px;
}