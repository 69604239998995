// remove border
.is-borderless {
  border: 0 !important;
}

// remove background
.is-transparent {
  background-color: transparent;
}

// scrollable helpers
.is-scrollable {
  overflow: scroll;
}
.is-scrollable-y {
  overflow-y: scroll;
}
.is-scrollable-x {
  overflow-x: scroll;
}

// border helpers
.has-border-bottom {
  border-bottom: 1px solid $border !important;
}
.has-border-top {
  border-top: 1px solid $border !important;
}
.has-border-left {
  border-left: 1px solid $border !important;
}
.has-border-right {
  border-right: 1px solid $border !important;
}

// dashed border helpers
.has-border-bottom-dashed {
  border-bottom: 1px dashed $border !important;
}
.has-border-top-dashed {
  border-top: 1px dashed $border !important;
}
.has-border-left-dashed {
  border-left: 1px dashed $border !important;
}
.has-border-right-dashed {
  border-right: 1px dashed $border !important;
}

.has-cursor-pointer {
  cursor: pointer;
}