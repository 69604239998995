// make panel heading uppercase and semibold
.panel-heading {
  text-transform: uppercase;
  font-weight: 600;
}

// add more padding on panel block
.panel-block {
  padding: 0.75em;
}

// provide a scrollable panel block
.panel-scroll {
  max-height: 250px;
  overflow-y: scroll;
}

// custom class for delete button on hover
.panel-block.is-deletable {
  position: relative;

  button.delete {
    right: 15px;
    position: absolute;
    display: none;
  }

  &:hover {
    button.delete {
      display: block;
    }
  }
}

// custom class for edit button on hover
.panel-block.is-editable {
  position: relative;

  button.edit {
    right: 15px;
    position: absolute;
    display: none;
    background-color: transparent;
    border: 0;
    cursor: pointer;

    .icon {
      color: $grey-lighter;
      transition: color 0.2s ease;
    }
  }

  &:hover {
    button.edit {
      display: block;
      .icon {
        color: $grey-dark;
      }
    }
  }
}

// move the edit icon further right when
// there is also a delete button there
.panel-block.is-editable.is-deletable {
  button.edit {
    right: 25px;
  }
}

.panel-block.is-filter {
  position: relative;

  button.delete {
    top: 20px;
    right: 15px;
    position: absolute;
  }
}