// change tag colors and add color helpers
.el-tag {

  height: 2.25rem;
  color: $primary;
  border-color: lighten($primary, 20%);
  background-color: lighten($primary, 48%);
  padding: 0.125rem 0.625rem;

  &.is-blue {
    color: $blue;
    border-color: lighten($blue, 20%);
    background-color: lighten($blue, 50%);
  }

  &.el-tag--success, &.is-green {
    color: $green;
    border-color: lighten($green, 20%);
    background-color: lighten($green, 50%);
  }

  &.el-tag--danger, &.is-red {
    color: $red;
    border-color: lighten($red, 20%);
    background-color: lighten($red, 37%);
  }

  &.el-tag--warning, &.is-orange {
    color: $orange;
    border-color: lighten($orange, 20%);
    background-color: lighten($orange, 48%);
  }

}