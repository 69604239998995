@media (max-width: 720px) {

  body {
    font-size: 0.92rem;
    line-height: 1.25rem;
  }

  .columns {
    .column {
      padding: 0.5rem;
    }
  }

  .input.is-medium, .textarea.is-medium, .button.is-medium {
    height: 2.25rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  .notification:not(:last-child) {
    margin-bottom: 0.75rem;
    padding: 0.75rem 1rem;
  }

  .proposal-form {
    .proposal-form-submit {
      text-align: center;
    }
  }
  .notification {
    padding: 0.75rem 1.5rem;
  }
  .program-logo {
    width: 150px;
    height: 150px;
  }
  .program-title {
    font-size: 18px;
  }
  .program-description {
    font-size: 14px;
    line-height: 26px;
  }

  .label:not(:last-child) {
    margin-bottom: 0.25rem;
    font-size: 0.6rem;
  }

  .box.p-25 {
    padding: 20px !important;
  }

  .campaign-title.is-5 {
    font-size: 14px;
    margin-bottom: 3px !important;
  }
  .campaign-by {
    font-size: 12px !important;
  }

  .campaign-details-by strong {
    color: #fff;
    font-size: 14px;
  }

  .cash-price {
    display: block;
    height: 64px;
    padding-top: 15px;
  }

  .box {
    border-radius: 0;
    border: 0;
    padding: 1.25rem;

    &.box-rounded {
      border-radius: 2px;
    }
  }

  .task-title-row {
    float: left;
    width: 100%;
    .task-title-col {
      float: left;

      &.task-check {
        width: 30px;
        float: right;
        .el-checkbox__inner {
          width: 32px;
          height: 32px;
        }
        .el-checkbox__inner::after {
          top: 6px;
          left: 7px;
        }
      }
      &.task-icon {
        width: 30px;
        height: 32px;
        padding: 5px;
        margin-top: 2px;
        .icon {
          font-size: 30px !important;
        }
      }
      &.task-type {

        margin-top: 7px;
        font-size: 14px;
        width: calc(100% - 75px);
        margin-left: 10px;
        line-height: 17px;
      }
    }
  }

  #campaign-tasks {
    margin-top: -15px;
    margin-bottom: 90px;
  }

  .content:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  .campaign-form-title {
    font-size: 0.75rem;
  }

  .campaign-details-content {
    padding: 2rem;

    .campaign-details-by  {
      font-size: 0.7rem;
    }
    h3 {
      font-size: 1.25rem;
      line-height: 1.5rem;
    }
    p {
      font-size: 0.9rem;
      font-weight: 400;
      line-height: 1.1rem;
    }
  }

  .content-rights-preview-card {
    max-height: 250px;
    padding: 1rem;
  }
}