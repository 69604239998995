@import "/home/jenkins/agent/workspace/deployments/grin.live/deploy-grin.live/resources/assets/node_modules/@grininc/grin-ui/style/variables/index.scss";











































.float-on-mask {
    position: relative;
    z-index: 1002;
}
