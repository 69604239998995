// base overlay
.is-overlay {
  position: absolute;
  z-index: 9;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;

  // opacity based helpers
  &.is-overlay-10 { background-color: shade(0.10); }
  &.is-overlay-15 { background-color: shade(0.15); }
  &.is-overlay-20 { background-color: shade(0.20); }
  &.is-overlay-25 { background-color: shade(0.25); }
  &.is-overlay-30 { background-color: shade(0.30); }
  &.is-overlay-35 { background-color: shade(0.35); }
  &.is-overlay-40 { background-color: shade(0.40); }
}