.menu-list a {

  // lighten the hover bg color
  &:hover {
    background-color: lighten(rgb(238, 240, 242), 3%);
  }

  // change active bg color
  &.is-active {
    background-color: rgb(238, 240, 242);
    color: $grey-darker;
  }
}

.menu-list.menu-steps {
  // add more padding to list items
  padding: 0.75em;
}
