// change active and hover color
.el-pager li.active,
.el-pager li:hover {
  color: $primary;
}

// change number size and color
.el-pager li,
.el-pagination .btn-next .el-icon,
.el-pagination .btn-prev .el-icon {
  font-size: 1.15rem;
  color: $grey;
  font-weight: 400;
}


.el-pager li, .el-pagination button, .el-pagination span:not([class*=suffix]) {
  border-radius: 2px;
  background-color: #f6f7f8;
  margin-right: 2px;
  @media only screen and (max-width: 800px) {
    line-height: 16px;
    font-size: 12px;
    height: 22px;
    width: 22px;
    min-width: 22px;
    padding: 3px 0;
  }
}

.el-pager li.number {

  &.active {
    background-color: $primary;
    color: #ffffff;
  }
}

// change button color
.el-pagination .btn-next .el-icon,
.el-pagination .btn-prev .el-icon {
  color: $grey-dark;
  &:hover {
    color: $primary;
  }
}

// change disabled button icon color
.el-pagination button.disabled .el-icon {
  color: $grey-lighter;
  &:hover {
    color: $grey-lighter;
  }
}