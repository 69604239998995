@import "/home/jenkins/agent/workspace/deployments/grin.live/deploy-grin.live/resources/assets/node_modules/@grininc/grin-ui/style/variables/index.scss";










































































































































































.connected-icon {
    color: $g-color-green-5;
    margin-right: $g-spacing-2;
}
.contact-network-line span {
    margin-right: $g-spacing-2;
}
.contact-network-status-level-item {
    height: 32px;
    & h5 {
        padding-right: $g-spacing-2;
    }
}
.gi-tiktok {
    color: #000;
    width: 1.25rem;
    display: inline-block;
    text-align: center;
    margin-right: $g-spacing-3 !important;
}
