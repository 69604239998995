.product-image {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-position: 50% !important;
}

.task-requirements {
  p {
    margin-bottom: 0.8rem;
    font-size: 1rem;
    line-height: 22px;
  }
}

.content-rights-preview-card {
  border-radius: $border-radius-default;
  padding: $box-padding;
  background-color: $white-ter;
  max-height: 500px;
  overflow-y: auto;
}
