@import "/home/jenkins/agent/workspace/deployments/grin.live/deploy-grin.live/resources/assets/node_modules/@grininc/grin-ui/style/variables/index.scss";













































































.nowrap {
    white-space: nowrap;
    & span {
        font-size: 14px;
    }
}

.grin-logo {
    margin-top: -2px;
}

.links a {
    color: $g-color-grey-6 !important;
    &:hover {
        color: $g-color-primary-5 !important;
    }
}
