// we are basically recreating the beufy toast
// by adding styles to the element ui message
.el-message {
  border-radius: 100px;
  text-align: center;
  opacity: 0.98;
  padding: 1.25em 1.75em;
  border: 0;
  transition: transform .15s ease-out, opacity .15s ease-out;
  @include shadow-large;

  .el-message__icon {
    display: none;
  }

  .el-message__content {
    width: 100%;
    text-align: center;
    font-size: 1rem;
  }

  &.el-message--error, &.el-message--success {
    .el-message__content {
      color: $white;
      line-height: 1.5rem;
      @include text-shadow-default;
    }
  }
  &.el-message--success {
    background-color: $green;
  }
  &.el-message--error {
    background-color: $red;
  }
}
