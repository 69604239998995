// add more left-right padding to navbar links
.navbar-menu .navbar-end a.navbar-item {
  padding: 10px 25px;
}

// remove the border from the mobile menu toggle
// remove the hover and active background color
.navbar-burger {
  border: 0;
  &:hover, &:active, &.is-active {
    border: 0;
    background-color: transparent;
  }
}

// reduce the padding between the mobile bar and menu
.navbar-menu {
  @include mobile {
    padding-top: 0;
  }
}

// add a border to the top of the mobile menu
.navbar-end {
  @include mobile {
    border-top: 1px solid $border;
  }
}

// move the logo up 5px
.navbar-brand .navbar-item {
  margin-top: -5px;
}

// add custom styles to main navbar (.app-navbar)
.app-navbar  {
  border-bottom: 1px solid $border;
  .navbar-brand {
    margin-left: 10px;
    margin-right: 10px;
  }
  .navbar-start, .navbar-end {
    &.navbar-icons {
      a.navbar-item {
        padding: 10px;
      }
    }
    .navbar-item, .navbar-link {
      color: $grey-light;
      transition: color .15s ease;
      &:hover {
        color: $grey-darker;
        background-color: transparent !important;
      }
      &.is-active {
        color: $black-bis;
        background-color: transparent !important;
      }
    }
  }
  &.is-dark {
    background-color: $grey-dark;
    &.navbar-icons {
      a.navbar-item {
        @include dark-nav-link;
      }
    }
    .navbar-start, .navbar-end {
      .navbar-item, .navbar-link {
        @include dark-nav-link;
        &:hover {
          background-color: transparent;
        }
      }
    }
    .navbar-end {
      .navbar-item, .navbar-link {
        opacity: 1;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}