@import "/home/jenkins/agent/workspace/deployments/grin.live/deploy-grin.live/resources/assets/node_modules/@grininc/grin-ui/style/variables/index.scss";

// import Bulma base
@import "~bulma/sass/utilities/initial-variables";
@import "~bulma/sass/utilities/functions";

// import overrides
@import "variables/fonts";
@import "variables/colors";
@import "variables/elements";

// import Bulma styles
@import "~bulma";

// import functions
@import "functions/index";

// import mixins
@import "mixins/index";

// import bulma class overrides
@import "overrides/bulma/container";
@import "overrides/bulma/section";
@import "overrides/bulma/hero";
@import "overrides/bulma/text";
@import "overrides/bulma/form";
@import "overrides/bulma/box";
@import "overrides/bulma/button";
@import "overrides/bulma/navbar";
@import "overrides/bulma/panel";
@import "overrides/bulma/card";
@import "overrides/bulma/table";
@import "overrides/bulma/menu";
@import "overrides/bulma/notification";
@import "overrides/bulma/level";
@import "overrides/bulma/icon";
@import "overrides/bulma/tag";

// import element ui styles per component
@import "~element-ui/lib/theme-chalk/alert.css";
@import "~element-ui/lib/theme-chalk/tag.css";
@import "~element-ui/lib/theme-chalk/tabs.css";
@import "~element-ui/lib/theme-chalk/tab-pane.css";
@import "~element-ui/lib/theme-chalk/popover.css";
@import "~element-ui/lib/theme-chalk/radio.css";
@import "~element-ui/lib/theme-chalk/radio-group.css";
@import "~element-ui/lib/theme-chalk/radio-button.css";
@import "~element-ui/lib/theme-chalk/checkbox.css";
@import "~element-ui/lib/theme-chalk/checkbox-group.css";
@import "~element-ui/lib/theme-chalk/switch.css";
@import "~element-ui/lib/theme-chalk/dialog.css";
@import "~element-ui/lib/theme-chalk/tooltip.css";
@import "~element-ui/lib/theme-chalk/date-picker.css";
@import "~element-ui/lib/theme-chalk/select.css";
@import "~element-ui/lib/theme-chalk/option.css";
@import "~element-ui/lib/theme-chalk/option-group.css";
@import "~element-ui/lib/theme-chalk/slider.css";
@import "~element-ui/lib/theme-chalk/carousel.css";
@import "~element-ui/lib/theme-chalk/carousel-item.css";
@import "~element-ui/lib/theme-chalk/pagination.css";

// import the element ui overrides
@import "overrides/element-ui/icons";
@import "overrides/element-ui/popover";
@import "overrides/element-ui/radio";
@import "overrides/element-ui/checkbox";
@import "overrides/element-ui/message";
@import "overrides/element-ui/tag";
@import "overrides/element-ui/tooltip";
@import "overrides/element-ui/dialog";
@import "overrides/element-ui/input";
@import "overrides/element-ui/select";
@import "overrides/element-ui/datepicker";
@import "overrides/element-ui/tabs";
@import "overrides/element-ui/slider";
@import "overrides/element-ui/pagination";

// import antd class overrides
@import "overrides/antd/drawer";

// import helpers
@import "helpers/text";
@import "helpers/layout";
@import "helpers/spacing";
@import "helpers/image";
@import "helpers/icon";
@import "helpers/table";
@import "helpers/other";

// import components
@import "components/nav";
@import "components/blankstate";
@import "components/campaigns";
@import "components/proposals";
@import "components/jobs";
@import "components/overlay";
@import "components/dashboard";
@import "components/join";
@import "components/dropzone";
@import "components/modal";
@import "components/product-wizard";
@import "components/logos";

// import mobile overrides
@import "overrides/mobile";

