@import "/home/jenkins/agent/workspace/deployments/grin.live/deploy-grin.live/resources/assets/node_modules/@grininc/grin-ui/style/variables/index.scss";








































































































































































































































.text-align-center {
    text-align: center;
}
.continue-button {
    background-color: #5c61ff;
    border: solid 1px #5c61ff;
    &:hover {
        background-color: #4246e7;
        border-color: #4246e7;
    }
}
::v-deep .FullscreenDrawer__content {
    max-width: 400px !important;
}
::v-deep .ant-drawer-content-wrapper {
    box-shadow: none !important;
}
