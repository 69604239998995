// add more margin between fields
.field:not(:last-child) {
  margin-bottom: 1.5rem;
}

// add a is-short helper for tigher fields
.field.is-short:not(:last-child) {
  margin-bottom: 1rem;
}

.label:not(:last-child) {
  margin-bottom: 0.1rem !important;
}

// add more spacing and a border between fields of step based forms
.step-fields {
  .field:not(:last-child) {
    margin-bottom: 1.75rem;
    padding-bottom: 1.75rem;
    border-bottom: 1px solid lighten($border, 5%);
  }
}

// change the label a bit
.label, .field > .label {
  font-size: 0.75rem;
  color: $grey-dark;
  text-transform: uppercase;
}

// add more bottom margin
.label:not(:last-child) {
  margin-bottom: 0.5rem;
}

// add more padding for medium inputs
.input, .textarea, .button {
  &.is-medium {
    height: 3.5rem;
    font-size: 1rem;
    padding-left: 1rem;
  }
}

// add more padding for select
.select.is-medium {
  &>select {
    height: 3.5rem;
    font-size: 1rem;
    padding-left: 1rem;
  }
  &:not(.is-multiple) {
    height: 3.5rem;
  }
}


// add a light grey background and
// remove box shadow on inputs
.input, .textarea, .select > select {
  background-color: $white-bis;
  @include shadow-input-inner;
}

.input, .textarea {
  &::placeholder {
    color: $placeholder;
  }
}

// reduce the icon size for medium inputs
.control.has-icons-left {
  .input, .select {
    &.is-medium ~ .icon {
      font-size: 1rem;
      height: 3.5rem;
      width: 2.5em;
    }
  }
}

// make icons clickable
.control.has-icons-left .icon,
.control.has-icons-right .icon {
  &.is-clickable {
    pointer-events: auto;
    cursor: pointer;
    &:hover {
      color: $grey-darker;
    }
  }
}

// add more top margin to the help text
.help {
  margin-top: 0.5rem;
  font-style: italic;
  color: $grey-light;
}

// remove right margin on file icon
.file-icon {
  margin-right: 0;
}

// change the font size and padding for medium file input
.file.has-name.is-medium {
  .file-name {
    font-size: 1rem;
    height: 2.8em;
    padding-top: 10px;
  }
  .file-cta {
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
    height: 2.25em;
    .file-label, .file-icon {
      font-size: 1rem;
      margin-top: 0;
    }
    .file-icon {
      margin-right: 0.5em;
      // move the icon up a bit
      i {
        height: 111%;
      }
    }
  }
}

// make the file cta more button like
.file.is-primary .file-cta {
  border-radius: $border-radius-default;
  @include text-shadow-default;
}

.file .file-cta .file-label {
  margin-top: 5px;
}