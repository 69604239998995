@import "/home/jenkins/agent/workspace/deployments/grin.live/deploy-grin.live/resources/assets/node_modules/@grininc/grin-ui/style/variables/index.scss";





















p,
ul {
    font-size: 0.9em;
}
