// add an even smaller icon width size for
// the tag buttons in the search secton
.icon.is-tiny {
  width: 0.75rem;
}

// make the icons inside the tags smaller and centered
.tag > .icon.is-tiny {
  font-size: 0.6rem;
  margin-left: 2px;
  margin-right: 0;
  opacity: 0.5;
  transition: opacity 0.2s ease-out;
}

// change color if its a link
.tag {
  a.icon:hover {
    opacity: 1;
  }
  a.icon.is-tiny {
    color: $grey-dark;
  }
  &.is-danger, &.is-success {
    a.icon.is-tiny {
      color: $white;
    }
  }
}