// app colors
$primary: #10b7eb;
$primary-invert: findColorInvert($primary);

// primary colors
$darkblue: #3f508a;
$orange: hsl(27, 100%, 56%) !default;
$yellow: hsl(48,  100%, 67%) !default;
$green: hsl(141, 71%,  48%) !default;
$turquoise: hsl(171, 100%, 41%) !default;
$blue: hsl(217, 71%,  53%) !default;
$purple: hsl(271, 100%, 71%) !default;
$red: hsl(348, 100%, 61%) !default;

// color shades
$black: hsl(212, 13%, 4%);
$black-bis: hsl(212, 13%, 7%);
$black-ter: hsl(212, 13%, 14%);
$grey-darker: hsl(212, 13%, 21%);
$grey-dark: hsl(212, 13%, 26%);
$grey: hsl(212, 13%, 40%);
$grey-light: hsl(212, 13%, 61%);
$grey-lighter: hsl(212, 13%, 86%);
$white-ter: hsl(212, 13%, 94%);
$white-bg: hsl(212, 13%, 97%);
$white-bis: hsl(212, 13%, 98%);
$white: hsl(0, 0%, 100%);
$placeholder: #a3a9b1;

// derived interface colors
$background: $white-ter;
$dark-background: $grey-dark;
$body-background-color: #eef0f2;
$body-color: $grey-dark;
$border: $grey-lighter;
$border-hover: $grey-light;
$text: $grey-dark;
$text-invert: findColorInvert($text);
$text-light: $grey-light;
$text-strong: inherit;

// social colors
$twitch: #7d5bbe;
$tumblr: #36465d;
$youtube: #e52d27;
$twitter: #00aced;
$facebook: #3B5998;
$linkedin: #0077B5;
$pinterest: #bd081c;
$instagram: #125688;

// file type colors
$pdf: #e22919;
$doc: #01addf;
$dox: #01addf;
$xls: #008641;
$xlsx: #008641;

// gender colors
$male: #2766d8;
$female: #d51497;
$neutral: #7e3c83;

// link colors
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;

// create bulma color helpers
$colors: (
  "white": ($white, findColorInvert($black)),
  "black": ($black, findColorInvert($white)),

  "light": ($white-ter, findColorInvert($white-ter)),
  "dark": ($grey-darker, findColorInvert($grey-darker)),
  "grey-light": ($grey-light, findColorInvert($grey-light)),
  "grey-lighter": ($grey-lighter, findColorInvert($grey-lighter)),
  "white-ter": ($white-ter, findColorInvert($white-ter)),
  "white-bis": ($white-bis, findColorInvert($white-bis)),

  "primary": ($primary, findColorInvert($primary)),
  "turquoise": ($turquoise, findColorInvert($turquoise)),
  "info": ($blue, findColorInvert($blue)),
  "success": ($green, findColorInvert($green)),
  "warning": ($yellow, findColorInvert($yellow)),
  "danger": ($red, findColorInvert($red)),

  "twitch": ($twitch, findColorInvert($twitch)),
  "tumblr": ($tumblr, findColorInvert($tumblr)),
  "youtube": ($youtube, findColorInvert($youtube)),
  "twitter": ($twitter, findColorInvert($twitter)),
  "facebook": ($facebook, findColorInvert($facebook)),
  "linkedin": ($linkedin, findColorInvert($linkedin)),
  "pinterest": ($pinterest, findColorInvert($pinterest)),
  "instagram": ($instagram, findColorInvert($instagram)),

  "pdf": ($pdf, findColorInvert($pdf)),
  "doc": ($doc, findColorInvert($doc)),
  "dox": ($dox, findColorInvert($dox)),
  "xls": ($xls, findColorInvert($xls)),
  "xlsx": ($xlsx, findColorInvert($xlsx)),

  "male": ($male, findColorInvert($male)),
  "female": ($female, findColorInvert($female)),
  "neutral": ($neutral, findColorInvert($neutral))
);

$shades: (
  "black-bis": $black-bis,
  "black-ter": $black-ter,
  "grey-darker": $grey-darker,
  "grey-dark": $grey-dark,
  "grey": $grey,
  "grey-light": $grey-light,
  "grey-lighter": $grey-lighter,
  "white-ter": $white-ter,
  "white-bis": $white-bis
);

