.button {

  outline: none !important;

  // remove shadow and lighten border for focus
  // and active buttons
  &:active, &.is-active, &:focus, &.is-focus,
  &:focus:not(:active), &.is-focused:not(:active) {
    border-color: $grey-darker !important;
    box-shadow: none !important;

    &.is-borderless {
      border: 0 !important;
    }

    &:hover {
      border-color: $grey-darker !important;
    }
  }

  // lighten the icon for disabled buttons
  &[disabled] i {
    opacity: 0.25;
  }

  &.is-primary {
    @include text-shadow-default;

    &.is-outlined {
      text-shadow: none;
    }
  }

  &.is-link {
    text-decoration: none;
  }

  &.is-text {
    background-color: transparent;
    border-color: transparent;
    color: #4a4a4a;
    text-decoration: underline;
    padding-left: 0;
  }

  &.is-light.is-cancel {
    background-color: $white-ter;
    opacity: 0.75;
    transition: opacity 0.2s ease, background 0.2s ease, color 0.2s ease;
    &:hover {
      background-color: darken($white-ter, 2%);
      opacity: 1;
    }
  }

  &.is-small {
    border-radius: $border-radius-small;
  }

  &.is-medium {
    padding-left: 2rem;
    padding-right: 2rem;
    border-radius: $border-radius-default;
  }

  &.is-large {
    font-size: 1.25rem;
    height: 54px;
    padding-left: 2rem;
    padding-right: 2rem;
    border-radius: $border-radius-default;
  }

  &.is-link {
    background-color: transparent !important;
    border-color: transparent !important;
  }
}

// plain buttom hovers
.button.has-hover-green:hover {
  color: $green !important;
  border-color: $green !important;
}
.button.has-hover-blue:hover {
  color: $blue !important;
  border-color: $blue !important;
}
.button.has-hover-orange:hover {
  color: $orange !important;
  border-color: $orange !important;
}
.button.has-hover-red:hover {
  color: $red !important;
  border-color: $red !important;
}
.button.has-hover-primary:hover {
  color: $primary !important;
  border-color: $primary !important;
}
.button.has-hover-turquoise:hover {
  color: $turquoise !important;
  border-color: $turquoise !important;
}