// font color helpers
.text-dark, .has-hover-darker:hover {
  color: $grey-darker !important;
}
.text, .has-hover-dark:hover {
  color: $grey-dark !important;
}
.text-light {
  color: $grey !important;
}
.text-lighter {
  color: $grey-light !important;
}

.text-bottom-border {
  border-bottom: 1px solid $border;
}

// font weight helpers
.text-thin {
  font-weight: 300 !important;
}
.text-normal {
  font-weight: 400 !important;
}
.text-semibold {
  font-weight: 500 !important;
}
.text-bold {
  font-weight: 700 !important;
}

// font transforms
.text-italic {
  font-style: italic !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-lowercase {
  text-transform: lowercase !important;
}
.text-underline {
  text-decoration: underline !important;
}
.text-strike {
  text-decoration: line-through !important;
}

// text alignment
.text-center {
  text-align: center !important;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}

// font size helpers
.text-12 {
  font-size: .75rem !important;
}
.text-14 {
  font-size: .875rem !important;
}
.text-16 {
  font-size: 1rem !important;
}
.text-18 {
  font-size: 1.125rem !important;
}
.text-20 {
  font-size: 1.25rem !important;
}
.text-24 {
  font-size: 1.5rem !important;
}
.text-48 {
  font-size: 3rem !important;
}
.text-32 {
  font-size: 2rem !important;
}
.text-40 {
  font-size: 2.5rem !important;
}

// font colors
.has-text-green, .has-text-hover-green:hover {
  color: $green !important;
}
.has-text-red, .has-text-hover-red:hover {
  color: $red !important;
}
.has-text-blue, .has-text-hover-blue:hover {
  color: $blue !important;
}
.has-text-orange, .has-text-hover-orange:hover {
  color: $orange !important;
}
.has-text-yellow, .has-text-hover-yellow:hover {
  color: $yellow !important;
}
.has-text-primary, .has-text-hover-primary:hover {
  color: $primary !important;
}
.has-text-turquoise, .has-text-hover-turquoise:hover {
  color: $turquoise !important;
}

// multi class helpers
.is-price {
  color: $green;
  font-weight: bold;
  font-size: 1.25rem;
}

// truncate helpers
.truncate {
  @include truncate;

  display: initial;
  flex-shrink: 1;
  min-width: 0;
}
