// width 10px - 500px
@for $i from 1 through 50 {
  .w-#{$i * 10} { width: $i * 10px !important; }
  .mw-#{$i * 10} { max-width: $i * 10px !important; }
}

// zero margin
.m-0 { margin: 0 !important; }
.mb-0 { margin-bottom: 0 !important; }
.mt-0 { margin-top: 0 !important; }
.ml-0 { margin-left: 0 !important; }
.mr-0 { margin-right: 0 !important; }
.mlr-0 { margin-left: 0 !important; margin-right: 0 !important; }
.mtb-0 { margin-top: 0 !important; margin-bottom: 0 !important; }

// zero padding
.p-0 { padding: 0 !important; }
.pb-0 { padding-bottom: 0 !important; }
.pt-0 { padding-top: 0 !important; }
.pl-0 { padding-left: 0 !important; }
.pr-0 { padding-right: 0 !important; }
.plr-0 { padding-left: 0 !important; padding-right: 0 !important; }
.ptb-0 { padding-top: 0 !important; padding-bottom: 0 !important; }

// 1px - 5px
@for $i from 1 through 5 {

  // margin
  .m-#{$i * 1} { margin: $i * 1px !important; }
  .mb-#{$i * 1} { margin-bottom: $i * 1px !important; }
  .mt-#{$i * 1} { margin-top: $i * 1px !important; }
  .mtb-#{$i * 1} { margin-top: $i * 1px !important; margin-bottom: $i * 1px !important; }
  .ml-#{$i * 1} { margin-left: $i * 1px !important; }
  .mr-#{$i * 1} { margin-right: $i * 1px !important; }
  .mlr-#{$i * 1} { margin-left: $i * 1px !important; margin-right: $i * 1px !important; }

  // negative margin top
  .mtn-#{$i * 1} { margin-top: -($i * 1px) !important; }

  // padding
  .p-#{$i * 1} { padding: $i * 1px !important; }
  .pb-#{$i * 1} { padding-bottom: $i * 1px !important; }
  .pt-#{$i * 1} { padding-top: $i * 1px !important; }
  .ptb-#{$i * 1} { padding-top: $i * 1px !important; padding-bottom: $i * 1px !important; }
  .pl-#{$i * 1} { padding-left: $i * 1px !important; }
  .pr-#{$i * 1} { padding-right: $i * 1px !important; }
  .plr-#{$i * 1} { padding-left: $i * 1px !important; padding-right: $i * 5px !important; }

}

// 5px - 100px
@for $i from 1 through 20 {

  // margin
  .m-#{$i * 5} { margin: $i * 5px !important; }
  .mb-#{$i * 5} { margin-bottom: $i * 5px !important; }
  .mt-#{$i * 5} { margin-top: $i * 5px !important; }
  .mtb-#{$i * 5} { margin-top: $i * 5px !important; margin-bottom: $i * 5px !important; }
  .ml-#{$i * 5} { margin-left: $i * 5px !important; }
  .mr-#{$i * 5} { margin-right: $i * 5px !important; }
  .mlr-#{$i * 5} { margin-left: $i * 5px !important; margin-right: $i * 5px !important; }

  // negative margin top
  .mtn-#{$i * 5} { margin-top: -($i * 5px) !important; }

  // padding
  .p-#{$i * 5} { padding: $i * 5px !important; }
  .pb-#{$i * 5} { padding-bottom: $i * 5px !important; }
  .pt-#{$i * 5} { padding-top: $i * 5px !important; }
  .ptb-#{$i * 5} { padding-top: $i * 5px !important; padding-bottom: $i * 5px !important; }
  .pl-#{$i * 5} { padding-left: $i * 5px !important; }
  .pr-#{$i * 5} { padding-right: $i * 5px !important; }
  .plr-#{$i * 5} { padding-left: $i * 5px !important; padding-right: $i * 5px !important; }

}