// Gradient mixins
@mixin dark-gradient() {
  background-image: linear-gradient(124deg, $grey-dark, $grey);
}
@mixin primary-gradient() {
  background-image: linear-gradient(124deg,
    lighten($turquoise, 5%),
    lighten($primary, 5%),
    lighten($primary, 10%)
  );
}
@mixin primary-gradient-hover() {
  background-image: linear-gradient(124deg,
    $turquoise,
    $primary,
    lighten($primary, 5%)
  );
}
@mixin primary-gradient-reversed() {
  background-image: linear-gradient(124deg,
          lighten($primary, 10%),
          lighten($primary, 5%),
          lighten($turquoise, 5%)
  );
}

// Box shadow mixins
@mixin shadow-medium() {
  box-shadow: 0 1px 4px shade(0.12), 0 0 6px shade(0.04);
}
@mixin shadow-large() {
  box-shadow: 0 2px 8px shade(0.15), 0 0 6px shade(0.075);
}
@mixin shadow-input-inner() {
  box-shadow: inset 0 1px 2px rgba(9, 10, 12, 0.075);
}

// Text shadow mixins
@mixin text-shadow-default() {
  text-shadow: 0 1px 2px shade(0.1);
}

// Nav bar dark items mixin
@mixin dark-nav-link() {
  color: $white;
  transition: opacity 0.15s ease;
  opacity: 0.75;
  &:hover {
    opacity: 0.95;
  }
  &.is-active {
    opacity: 1;
    font-weight: 600;
  }
}

// truncate text mixin
@mixin truncate() {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}