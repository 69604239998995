.join-container {
  max-width: 600px;
}

.program-logo {
  display: inline-block;
  margin-bottom: 20px;
  margin-top: 25px;
  img {
    max-width: 150px;
  }
}

.program-title {
  //color: $darkblue;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 15px;
}

.program-description {
  //color: $darkblue;
  font-size: 16px;
  line-height: 30px;
}

.field:not(:last-child) {
  margin-bottom: 0.75rem;
}