// responsive table helper
table.is-responsive {
  border-collapse: collapse;
  overflow-x: auto;
  width: fit-content;
  max-width: 100%;

  th, td {
    white-space: nowrap;
  }
}

table.is-datatable {
  thead {
    th {
      cursor: pointer;
      &:hover {
        color: $black-ter !important;

        .fa-sort {
          color: $black-ter !important;
        }
      }
      &.is-active {
        color: $primary !important;
      }
    }
  }
}

// table cell width helpers
td.cell-checkbox, th.cell-checkbox {
  width: 56px;
  text-align: center;
}