.blank-state {
  position: relative;
  text-align: center;
  background-color: $white;
  border-radius: $border-radius-default;
  box-shadow: 0 2px 3px shade(0.1), 0 0 0 1px shade(0.1);
  //border: 1px solid $border;

  &.blank-state-inner {
    box-shadow: none;
    border-radius: 0;
    border: 0;
  }

  &.blank-state-small {
    padding: 60px 40px 70px;
  }

  &.blank-state-large {
    padding: 100px 60px 120px;
  }

  &.blank-state-huge {
    padding: 130px 60px 150px;
  }

  &.blank-state-no-border {
    border: 0;
  }

  &>i {
    font-size: 50px;
    opacity: 0.15;
    display: block;
    text-align: center;
    margin-bottom: 25px;
    color: $grey-dark;
  }

  .blank-state-title {
    font-size: 1.25rem;
    font-weight: 600;
    opacity: 0.8;
    text-align: center;
    margin-bottom: 2px;
    color: $grey-dark
  }

  .blank-state-content {
    color: $grey-light;
    font-size: 1rem;
    line-height: 1.4rem;
  }

  @media only screen and (max-width: 800px) {
    .blank-state-title {
      font-size: 1rem;
    }
    .blank-state-content {
      font-size: 14px;
    }
    &>i {
    font-size: 42px;
    }
  }
}