.el-dialog.product-modal {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  box-shadow: none;
  margin-top: 5vh !important;
  height: 90vh;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 800px) {
    margin-top: 0 !important;
    height: 100vh;
  }

  .el-dialog__body {
    padding: 0;
    display: flex;
    flex: 1 1 auto;
    overflow: hidden;
    flex-direction: column;

    .product-wizard {
      overflow: hidden;
      display: flex;
      flex: 1 1 auto;
      flex-direction: column;
    }

    .modal-inner {
      padding: 20px;
      max-height: 90vh;
      overflow-y: scroll;
      overflow-x: hidden;
      @media only screen and (max-width: 800px) {
        padding: 15px;
        p {
          line-height: 18px;
        }
      }
    }

    .columns .column {
      padding: 0.25rem;
    }

    .card {
      box-shadow: none !important;
      border: 1px solid #e5e8eb !important;

      .product-title {
        font-weight: 500;
        line-height: 18px;

        @media only screen and (max-width: 800px) {
          line-height: 16px;
          font-size: 12px;
        }
      }

      .add-button {
        background-color: #111111;
        border-color: #111111;
        color: #ffffff;
      }
    }
  }
}

.el-dialog.product-modal .el-dialog__header {
  background-color: #f6f7f8;
  border-bottom: 1px solid #e5e8eb;
  padding: 10px 12px 6px;

  @media only screen and (max-width: 800px) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 8px 10px 6px;
    .el-dialog__title {
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 500;
    }

    .el-dialog__headerbtn {
      top: 12px;
    }
  }

}

.el-dialog.cool-modal {
  width: 96%;
  max-width: 600px;
  margin-top: 2vh !important;
  border-radius: $border-radius-default;
  box-shadow: 0 1px 15px rgba(0,0,0,.5);

  @media (max-width: 800px) {
    margin-top: 7px !important;
  }

  &.cool-modal-wide {
    max-width: 800px;

    &.cool-modal-video {
      max-width: 60%;
      min-width: 600px;
    }
  }

  .el-dialog__header {
    background-color: lighten($background, 3%);
    border-top-left-radius: $border-radius-default;
    border-top-right-radius: $border-radius-default;
    border-bottom: 1px solid lighten($border, 5%);
    padding: 20px 24px 16px;
  }

  .el-dialog__title {
    color: $grey-darker;
    font-weight: 500;
  }

  .el-dialog__headerbtn {
    top: 24px;
    right: 24px;
    opacity: 0.75;
    transition: opacity 0.2s ease-out;
    .el-icon-close {
      color: $grey-dark;
      font-size: 20px;
    }
    &:hover {
      opacity: 1;
    }
  }

  .el-dialog__body {
    padding: 0;



    .modal-inner {
      padding: 20px;
      max-height: 85vh;
      overflow-y: scroll;
      @media only screen and (max-width: 800px) {
        padding: 15px;
        p {
          line-height: 20px;

        }
      }
    }
  }

  .el-dialog__footer {
    text-align: left;
    padding: 10px 20px 15px;
    background-color: lighten($background, 3%);
    border-top: 1px solid lighten($border, 5%);
    border-bottom-left-radius: $border-radius-default;
    border-bottom-right-radius: $border-radius-default;

    button.is-cancel {
      float: right;
      background-color: transparent !important;
    }
  }

}