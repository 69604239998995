@import "/home/jenkins/agent/workspace/deployments/grin.live/deploy-grin.live/resources/assets/node_modules/@grininc/grin-ui/style/variables/index.scss";











































































































































































































































































































































































































































































































































































































































































































.connect-wrapper {
    text-align: center;

    .margin-bottom-normal {
        margin-bottom: $g-spacing-5;
    }

    .margin-top-2 {
        margin-top: $g-spacing-7;
    }
    .margin-bottom-3 {
        margin-bottom: $g-spacing-9;
    }
    .h1 {
        max-width: 320px;
        text-align: center;
        margin: 0 auto $g-spacing-6 auto;
    }
    .logo-style {
        width: 183px;
        margin-top: $g-spacing-7;
        margin-bottom: $g-spacing-7;
    }

    .text-wrapper {
        margin: 0 auto;
        width: 310px;
    }

    .connect-button {
        padding: 0 3rem;
    }

    ::v-deep .ant-alert {
        text-align: left;
    }
}
