// add a light background to the header row
.table thead td, .table thead th {
  background-color: $white-ter;
  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: 1.625rem;
  vertical-align: middle;
  padding: 1em;
}

// transparent header
.table thead.is-transparent th {
  background-color: transparent;
  border-bottom-width: 1px;
  &:hover {
    background-color: transparent;
  }
}
.table.is-striped thead tr:hover,
.table thead.is-transparent tr:hover {
  background-color: transparent !important;
}

// change hover color of striped table
.table.is-striped tr:hover {
  background-color: $white-bg !important;
}

// add more padding to the body cells
.table td, .table th {
  padding: 1em;
  vertical-align: middle;
  border-color: shade(0.1);
}

// align images in the middle
.table td img {
  vertical-align: middle;
}

// change is-selected row
.table tr.is-selected {
  color: inherit !important;
  background-color: darken($white-bg, 1%) !important;

  // change the td, th border {
  td, th {
    border-color: shade(0.1);
  }
}

// add a compact table
.table.is-compact {
  td, th {
    padding: 0.5em 0.75em;
  }
}