@import "/home/jenkins/agent/workspace/deployments/grin.live/deploy-grin.live/resources/assets/node_modules/@grininc/grin-ui/style/variables/index.scss";
































































































































































































































































































.shared-networks {
    display: flex;
    flex-flow: column;
    justify-content: center;
}
.brand-toggle {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &-wrapper {
        width: 100%;
    }
}
.shared-networks-disclaimers {
    span {
        color: $g-color-primary;
    }
    span:hover {
        cursor: pointer;
    }
}
.connection-no-button {
    cursor: pointer;
}
