// change the color of the dark hero
.hero.is-dark {
  background-color: $grey-dark;
  @include dark-gradient();
}

// change the font color of the white hero
.hero.is-white {
  .title, .subtitle {
    color: $grey-dark;
  }
}

// add padding for small hero
.hero.is-small .hero-body {
  padding: 1.5rem;
}

// add the has-shadow helper to the hero
.hero.has-shadow {
  box-shadow: rgba(9, 10, 12, 0.1) 0 2px 3px;
}