// change the font size of the label
.el-radio__label {
  font-weight: normal;
  font-size: 0.95rem;
}

// make the radio button bigger
.el-radio__inner {
  width: 24px;
  height: 24px;
}

// change the dot size and transition
.el-radio__inner::after {
  opacity: 0;
  width: 10px;
  height: 10px;
  transition: none;
  transition: opacity .15s ease-out;
}
// change the checked dot color
.el-radio__input.is-checked .el-radio__inner::after {
  opacity: 1;
  background-color: $primary;
}

// change the border color of the radio circle
.el-radio__inner {
  border-color: $border;
  border-width: 2px;
  transition: none;
  transition: background 0.15 ease, border .15s ease;
}

.el-radio__inner:hover, .el-radio:hover .el-radio__inner  {
  border-color: $border;
  background-color: lighten($border, 10%);
}

// change the checked label color
.el-radio__input.is-checked + .el-radio__label {
  color: $black-ter;
}

// change the radio circle background
.el-radio__input.is-checked .el-radio__inner {
  background-color: $white;
  border-color: $primary;
}

// change the dot color to primary
.el-radio__input.is-checked .el-radio__inner::after {

}

