@import "/home/jenkins/agent/workspace/deployments/grin.live/deploy-grin.live/resources/assets/node_modules/@grininc/grin-ui/style/variables/index.scss";



































































.modal-footer {
    display: flex;
    justify-content: flex-end;
    padding: $g-spacing-7;
}
.margin-bottom-small {
    margin-bottom: $g-spacing-3;
}
