@import "/home/jenkins/agent/workspace/deployments/grin.live/deploy-grin.live/resources/assets/node_modules/@grininc/grin-ui/style/variables/index.scss";

































































































































.status_indicator_wrapper {
    position: relative;
}
.status_indicator {
    position: absolute;
    left: -2px;
    bottom: -1px;
    border: solid;
    border-radius: 50%;
    border-color: white;
    background-color: white;
}
.status_indicator--enabled {
    color: $g-color-green-5;
}
.status_indicator--disabled {
    color: $g-color-grey-4;
}
.network-icon-container {
    display: inline-block !important;
}
.gi-tiktok {
    color: #000;
}
