@import "/home/jenkins/agent/workspace/deployments/grin.live/deploy-grin.live/resources/assets/node_modules/@grininc/grin-ui/style/variables/index.scss";





























































































































































































































































































































.social-network-connect-line {
    vertical-align: top;
    display: inline-block;

    span,
    p {
        display: inline-block;
    }
}

.connect-network-btn {
    width: 90px;
}

.gi-tiktok {
    color: #000;
    width: 1.25rem;
    display: inline-block;
    text-align: center;
    margin-right: $g-spacing-3 !important;
}
