@import "/home/jenkins/agent/workspace/deployments/grin.live/deploy-grin.live/resources/assets/node_modules/@grininc/grin-ui/style/variables/index.scss";























.el-popover__title {
    font-weight: bold;
    font-size: 0.9em;
}
.networks-connect-info {
    font-size: 0.9em;
    p {
        margin-bottom: 10px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
