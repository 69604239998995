// make the tooltip a bit larger
.el-tooltip__popper {
  font-size: 0.9rem;
  padding: 12px 15px;

  // change the background color
  &.is-dark {
    background-color: $dark-background;
    @include text-shadow-default;
  }
}

// make sure the arrow is the same color - found in:
// node_modules/element-ui/lib/theme-chalk/tooltip.css
.el-tooltip__popper[x-placement^=top] .popper__arrow,
.el-tooltip__popper[x-placement^=top] .popper__arrow::after {
  border-top-color: $dark-background;
}
.el-tooltip__popper[x-placement^=bottom] .popper__arrow,
.el-tooltip__popper[x-placement^=bottom] .popper__arrow::after {
  border-bottom-color: $dark-background
}
.el-tooltip__popper[x-placement^=right] .popper__arrow,
.el-tooltip__popper[x-placement^=right] .popper__arrow::after {
  border-right-color: $dark-background;
}
.el-tooltip__popper[x-placement^=left] .popper__arrow,
.el-tooltip__popper[x-placement^=left] .popper__arrow::after {
  border-left-color: $dark-background
}